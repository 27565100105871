import React from 'react'
import Akglogo from '../../Images/Untitled-2.png'
import './Altheader.css'

function Altheader() {
  return (
    <div className='headerp1div'>
      <div className='headerp2div'>
        <div className='headerc1div'>
          <img src={Akglogo} alt="#" height="100%" width="100%"/>
        </div>
        </div>
        </div>
  )
}

export default Altheader
