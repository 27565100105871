import React, { useState } from "react";
import "./Altmain.css";
import Footer from "../Utils/Footer";
import Altheader from "../Utils/Altheader";
import axios from "axios";
import { Navigate } from "react-router-dom";
import { BounceLoader } from "react-spinners";
import Api from "../../Api";

export default function Altmain() {
  const [login, setLogin] = useState(false);
  const [loader, setLoader] = useState(true);

  const [values, setValues] = useState({
    email: "",
    password: "",
  });

  const handleForm = (event) => {
    const value = event.target.value;
    const name = event.target.name;
    setValues((prevValue) => {
      if (name === "email") {
        return {
          email: value,
          password: prevValue.password,
        };
      } else if (name === "password") {
        return {
          email: prevValue.email,
          password: value,
        };
      }
    });
  };

  const submitForm = async () => {
    setLoader(false);
    try {
      await axios
        .post(Api.login, values)
        .then(function (response) {
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user",JSON.stringify(response.data.user))
          localStorage.setItem("username",JSON.stringify(response.data.user.name))
          setLogin(true);
          console.log(response);
        });setLoader(true);
    } catch (e) {
      console.log("exception occured");
      console.log(e);
      setLoader(true);
      window.alert("Enter Correct Username or Password")
    }
  };

  if (login) {
    return <Navigate to="/test/phy" />;
  }

  return (
    <>
      {loader ? (
        <>
          <Altheader />
          <div className="maindiv1">
            <div className="maindiv2">
              <h2 className="mainhead1">START THE EXAM</h2>
              <p className="mainpara">Enter your details to start the exam.</p>
              <form>
                <div className="formdiv">
                  <b>Enter your Email Id</b>
                  <input
                    type="text"
                    className="input"
                    name="email"
                    value={values.email}
                    onChange={handleForm}
                  ></input>
                </div>
                <div className="formdiv">
                  <b>Enter your Phone Number</b>
                  <input
                    type="text"
                    className="input"
                    name="password"
                    value={values.password}
                    onChange={handleForm}
                  ></input>
                </div>
              </form>
              <button className="submitbtn" onClick={submitForm}>
                SUBMIT
              </button>
            </div>
          </div>
          <Footer />
        </>
      ) : (
        <div className="CentralLoader">
          <BounceLoader size={100} color={"#007ab6"} loading={true} />
        </div>
      )}
    </>
  );
}
