import React from 'react'
import './HeaderLogo.css'
import Akglogo from '../../Images/Untitled-2.png'
import Timer from './Timer'

function HeaderLogo(props) {
  const username = localStorage.getItem("username");
  console.log(username)
  return (
    <div className='headerp1div'>
      <div className='headerp2div'>
        <div className='headerc1div'>
          <img src={Akglogo} alt="#" height="100%" width="100%"/>
        </div>
        <div className='headerc2div'>
          {/* <h3 className='headerh'>ENTRANCE EXAM</h3> */}
          <h4 className='headerh'>CANDIDATE NAME: {username.slice(1,-1)}</h4>
          {props.show?(
            <div className="timerpdiv">
            <div className="timercdiv">REMAINING TIME:</div>
            <div className="timercdiv timerbg"><Timer /></div>
          </div>):(<></>)}
        </div>
      </div>
    </div>
  )  
}

export default HeaderLogo