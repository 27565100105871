import React, { useEffect} from "react";
// import React, { useEffect, useState } from "react";
import QuestionList from "./QuestionList.js";
import Questions from "./Questions.js";
// import Button from "@mui/material/Button";
import axios from "axios";
import Api from "../../Api.js";
// import TestPage from "../Utils/TestPage.js";

function QuestionsScroll(props) {
  // const [display, setDisplay] = useState(true);
  // const [value, setValues] = useState([]);
  // var resultObj = [];
  // const user = localStorage.getItem("user");
  
  useEffect(() => {
    LoadQuestions();
  }, []);

  const LoadQuestions = async () => {
    const user = JSON.parse(localStorage.getItem("user"))
    console.log(user)
    await axios
      .post(Api["get-questions"],{user})
      .then(function (response) {
        console.log(response.data.question);
        // localStorage.setItem("question",JSON.stringify(response.data))
        // getQuestion = JSON.parse(.getItem("question\")
        // setdata(initialData=>[...initialData, response.data.question]
        // console.log(data)
      })
      .catch(function (error) {
        console.log(error);
      });
  };

  // const submitAnswers = async () => {
  //   const user = JSON.parse(localStorage.getItem("user"))
  //   // console.log(value.question)
  //   try {
  //     await axios
  //       .post("https://brl1test.herokuapp.com/student/save-answer", {value,user})
  //       .then(function (response) {
  //           console.log(response)
  //       });
  //   } catch (e) {
  //     console.log("exception occured");
  //     console.log(e);
  //   }
  // };

  // const onSubmit = () => {
  //   QuestionList.map((item) =>
  //     resultObj.push({
  //         question: item._id.$oid,
  //         response: localStorage.getItem(item._id.$oid),
  //     })
  //   );
  //   console.log(resultObj);
  //   setValues(resultObj);
  //   if (value.length){
  //     submitAnswers()
  //     localStorage.removeItem("token");
  //     setDisplay(false)
  //   }
  //   else {
  //     window.alert("Are you sure ? Kindly Submit Again")
  //   }
  //   console.log(value)
  // };

  // if(localStorage.getItem("token") === null){
  //   return <Navigate to='/'/> ;
  //   // history.push('/')
  //   }

  var counter = 0;
  return (
    <>
      {/* {display ? (
        <> */}
          <div className="QuestionsScroll">
            {QuestionList.filter((sub) => sub.category === props.sub).map(
              (item, index) => (
                <Questions
                  key={item._id.$oid}
                  count={++counter}
                  questions={item.question}
                  op1={item.one}
                  op2={item.two}
                  op3={item.three}
                  op4={item.four}
                  queid={item._id.$oid}
                  category={item.categorynp}
                  correct={item.correct}
                />
              )
            )}
            <div className="submitbuttons">
              {/* <Button
          style={{
            borderColor: "#000000",
            margin: "5px 5px",
          }}
          sx={{
            color: "#000000",
          }}
          variant="outlined"
        >
          PREVIOUS SUBJECT
        </Button> */}
              {/* <Button
                style={{
                  backgroundColor: "#3ab54a",
                  margin: "30px 10px",
                }}
                variant="contained"
                onClick={onSubmit}
              >
                SUBMIT
              </Button> */}
              {/* <Button
          style={{
            borderColor: "#000000",
            margin: "30px 10px",
          }}
          sx={{
            color: "#000000",
          }}
          variant="outlined"
        >
          NEXT SUBJECT
        </Button> */}
            </div>
          </div>
        </>
      // ) 
      // : (
      //   <>
      //     <TestPage show={false}/>
      //   </>
      // )
      // }
    // </>
  );
}

export default QuestionsScroll;
