import React from "react";
import "./SubjectHeader.css";
// import { NavLink } from "react-router-dom";
// import { Link } from "react-router-dom";
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";

function SubjectHeader() {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      <div className="subject">
        {/* <div className="subs">
          <NavLink exact to="/test/phy" activeClassName="activeClass">
            <div className="btndiv">
              <button className="btn btn1" type="button" value="phy">
                Physics
              </button>
            </div>
          </NavLink>
          <Link exact to="/test/chem" activeClassName="activeClass">
            <div className="btndiv">
              <button className="btn btn2" type="button" value="chem">
                Chemistry
              </button>
            </div>
          </Link>
          <Link exact to="/test/math" activeClassName="activeClass">
            <div className="btndiv">
              <button className="btn btn3" type="button" value="maths">
                Maths
              </button>
            </div>
          </Link>
        </div> */}
        <div className="EnterenceHeader"><h3>ENTRANCE EXAM</h3></div>
        <div>
          <div className="instr">
            <button
              className="btn btn4"
              type="button"
              onClick={() => setOpen(true)}
            >
              Instructions
            </button>
            <Dialog
              open={open}
              onClose={() => setOpen(false)}
              aria-labelledby="dialog-title"
              aria-describedby="dialog-description"
            >
              <DialogTitle id="dialog-title">Instructions</DialogTitle>
              <DialogContent>
                <DialogContentText id="dialog-description">
                  1. The test comprises of 30 questions of 1 mark each divided
                  into 3 sections. There is no negative marking. <br/>2. The total
                  time for the test is 30 minutes after which the test would be
                  auto submitted. <br/>3. Click on the SUBMIT button only after you
                  have completed the test. The action is irreversible and your
                  test would be submitted. <br/>4. Electronic gadgets like mobile
                  phones, calculators, earphones, headphones, pagers, documents,
                  slide rules, log tables, tape recorders or cameras, etc are
                  prohibited inside the test hall.
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setOpen(false)}>Close</Button>
              </DialogActions>
            </Dialog>
          </div>
        </div>
      </div>
    </>
  );
}

export default SubjectHeader;
