import React from "react";
import "./QuestionList.js";
import "./Questions.css";

function Questions(props) {
  localStorage.setItem(props.queid,"")

  const handleChange = (event) => {
    const value = event.target.value;
    // const queslist = []
    // queslist.push({"question":props.queid, "response":value}) 
    // console.log(queslist)
    localStorage.setItem(props.queid,value)
    console.log(localStorage.getItem(props.queid))
  };


  return (
    <>
      <form>
        <div className="Questions">
          <h3 id="top">Question {props.count}: </h3>
          <div className="ques">
            <p id="middle">{props.questions}</p>
            <div className="option">
              <input
                type="radio"
                id={props.category}
                name={props.queid}
                value="one"
                onChange={handleChange}
                // checked={localStorage.getItem(props.queid) === "one"}
              />
              <div className="opt">
                (1) <span className="op">{props.op1}</span>
              </div>
            </div>
            <div className="option">
              <input
                type="radio"
                id={props.category}
                name={props.queid}
                value="two"
                onChange={handleChange}
                // checked={localStorage.getItem(props.queid) === "two"}
              />
              <div className="opt">
                (2) <span className="op">{props.op2}</span>
              </div>
            </div>
            <div className="option">
              <input
                type="radio"
                id={props.category}
                name={props.queid}
                value="three"
                onChange={handleChange}
                // checked={localStorage.getItem(props.queid) === "three"}
              />
              <div className="opt">
                (3) <span className="op">{props.op3}</span>
              </div>
            </div>
            <div className="option">
              <input
                type="radio"
                id={props.category}
                name={props.queid}
                value="four"
                onChange={handleChange}
                // checked={localStorage.getItem(props.queid) === "four"}
              />
              <div className="opt">
                (4) <span className="op">{props.op4}</span>{" "}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
export default Questions;
