import React from 'react'
import blockchainlogo from '../../Images/blockchainlogo.png'
import './Footer.css'
function Footer() {
  return (
    <div className="footer">
      <h5 className="fr">POWERED BY</h5>
      <img  className="img"src={blockchainlogo} alt="blockchainlogo" />
    </div>
  )
}

export default Footer
