import React from "react";
import blockchainlogo from "../../Images/blockchainlogo.png";
import "./EndFooter.css";

function EndFooter() {
  return (
    <div className="endfooter">
      <h5 className="fr">POWERED BY</h5>
      <img className="img" src={blockchainlogo} alt="blockchainlogo" />
    </div>
  );
}

export default EndFooter;
