import './App.css';
import { Route, Routes } from "react-router";
import TestPage from './Components/Utils/TestPage'
import HeaderLogo from './Components/Utils/HeaderLogo';
import SubjectHeader from './Components/Utils/SubjectHeader';
import Footer from './Components/Utils/Footer';
import Altmain from './Components/Auth/Altmain';
import Timer from './Components/Utils/Timer';

function App() {
  return (
    <div className="App">
      <Routes>
        <Route exact path='/test' element={<TestPage/>}/>
        <Route exact path='/header' element={<HeaderLogo/>}/>
        <Route exact path='/subject' element={<SubjectHeader/>}/>
        <Route exact path='/' element={<Altmain/>}/>
        <Route exact path='/test' element={<TestPage show={false}/>}/>
        <Route exact path='/test/phy' element={<TestPage sub="physics" show={true}/>}/>
        <Route exact path='/test/chem' element={<TestPage sub="chemistry" show={true}/>}/>
        <Route exact path='/test/math' element={<TestPage sub="maths" show={true}/>}/>
        <Route exact path='/footer' element={<Footer/>}/>
        <Route exact path='/auth' element={<Altmain/>}/>
        <Route exact path='/timer' element={<Timer/>}/>
      </Routes>
    </div>
  );
}

export default App;
