import React, { useState, useRef, useEffect } from "react";
// import { Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
// import QuestionList from "../Questions/QuestionList";
// import axios from "axios";
// import TestPage from "./TestPage";

function Timer() {
  
  const [submit, setSubmit] = useState(false);
  let navigate = useNavigate();
//   const [value, setValues] = useState([]);
//   var resultObj = [];
//   var counter = 1;

//   const submitAnswers = async () => {
//     // console.log(resultObj);
//     // const result = JSON.parse(resultObj)
//     // console.log(result)
//     const user = JSON.parse(localStorage.getItem("user"));
//     try {
//       await axios
//         .post("https://brl1test.herokuapp.com/student/save-answer", {
//         value,
//           user,
//         })
//         .then(function (response) {
//           console.log(response);
//         });
//     } catch (e) {
//       console.log("exception occured");
//       console.log(e);
//     }
//   };

//   const onSubmit = () => {
//       QuestionList.map(
//       (item) =>
//       setValues(value => [...value,{
//         count: counter++,
//         question: item._id.$oid,
//         response: localStorage.getItem(item._id.$oid),
//       }])
//         // resultObj.push({
//         //   count: counter++,
//         //   question: item._id.$oid,
//         //   response: localStorage.getItem(item._id.$oid),
//         // })
//     );
//     // setValues(value => [...value,resultObj]);
//     // setValues(value => [...value,resultObj]);
//     // setValues(resultObj)
//     // console.log(resultObj)
//     // setValues(resultObj)
//     console.log(value)
//     // // console.log(resultObj);
//     // if (value.length) {
//     //     console.log(value)
//     // } else {
//     //     window.alert("Test Ended")
//     // // onSubmit();
//     // }
//     // console.log(value);
//   };

  const Ref = useRef(null);
  const [timer, setTimer] = useState("00 : 00 : 00");

  const getTimeRemaining = (e) => {
    const total = Date.parse(e) - Date.parse(new Date());
    const seconds = Math.floor((total / 1000) % 60);
    const minutes = Math.floor((total / 1000 / 60) % 60);
    const hours = Math.floor(((total / 1000) * 60 * 60) % 24);
    return {
      total,
      hours,
      minutes,
      seconds,
    };
  };
  const startTimer = (e) => {
    let { total, hours, minutes, seconds } = getTimeRemaining(e);
    if (total >= 0) {
      setTimer(
        (hours > 9 ? hours : "0" + hours) +
          " : " +
          (minutes > 9 ? minutes : "0" + minutes) +
          " : " +
          (seconds > 9 ? seconds : "0" + seconds)
      );
    } else {
    //   if (!submit) {
        // onSubmit();
        // submitAnswers();
        // localStorage.removeItem("token");
    //   }
      // localStorage.removeItem("token")
      // window.alert("Test Ended")
      setSubmit(true);
    }
  };
  const clearTimer = (e) => {
    setTimer("00 : 30 : 00");
    if (Ref.current) clearInterval(Ref.current);
    const id = setInterval(() => {
      startTimer(e);
    }, 1000);
    Ref.current = id;
  };
  const getDeadTime = () => {
    let deadline = new Date();
    deadline.setSeconds(deadline.getSeconds() + 1800);
    return deadline;
  };

  useEffect(() => {
    clearTimer(getDeadTime());
  }, [submit]);

  if (submit) {
      navigate('/test')
  }

  return <div>{timer}</div>;
}
export default Timer;
