// const url = "https://brl1test.herokuapp.com";
// const url = "https://admi--portal.herokuapp.com";
const url = "https://api.admission.portal.brlakgec.com";
// const url = "http://3.109.153.104:3000";

const Api = {
    "login": url+"/login",
    "save-answer": url+"/student/save-answer",
    "get-questions": url+"/student/get-Allquestions",
}

export default Api;
