import React, { useState } from "react";
import HeaderLogo from "./HeaderLogo";
import SubjectHeader from "./SubjectHeader";
import Footer from "./Footer";
import QuestionsScroll from "../Questions/QuestionsScroll";
import Button from "@mui/material/Button";
import QuestionList from "../Questions/QuestionList";
import EndFooter from "./EndFooter";
import { Navigate } from "react-router-dom";
import './TestPage.css'
import axios from "axios";
import Api from "../../Api";

function TestPage(props) {
  const token = localStorage.getItem("token");
  const [isSubmitting, setIsSubmitting] = useState(false);

  const submitAnswers = async (value) => {
    const user = JSON.parse(localStorage.getItem("user"));
    try {
      await axios.post(Api["save-answer"], {
        value,
        user,
      });
      console.log("Answers submitted successfully");
      localStorage.removeItem("token");
      setIsSubmitting(false);
    } catch (error) {
      console.error("Error occurred while submitting answers:", error);
      setIsSubmitting(false);
    }
  };

  const onSubmit = () => {
    setIsSubmitting(true);
    const resultObj = QuestionList.map((item, index) => ({
      count: index + 1,
      question: item._id.$oid,
      response: localStorage.getItem(item._id.$oid),
    }));
    console.log(resultObj);
    const nonEmptyResponses = resultObj.filter(
      (item) => item.response !== null && item.response !== undefined
    );
    if (nonEmptyResponses.length > 0) {
      submitAnswers(nonEmptyResponses);
    } else {
      console.log("No answers to submit.");
      setIsSubmitting(false);
    }
  };

  if (token === null) {
    return <Navigate to='/'/>;
  }

  return (
    <div className="TestPage">
      {props.show ? (
        <>
          <div className="HeadDiv">
            <HeaderLogo show={true} />
            <SubjectHeader />
          </div>
          <h1>PHYSICS</h1>
          <QuestionsScroll sub="physics" />
          <hr />
          <h1>CHEMISTRY</h1>
          <QuestionsScroll sub="chemistry" />
          <hr />
          <h1>MATHS</h1>
          <QuestionsScroll sub="maths" />
          <div className="submitbuttons">
            <Button
              style={{
                backgroundColor: "#3ab54a",
                margin: "30px 10px",
              }}
              variant="contained"
              onClick={onSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "SUBMITTING..." : "SUBMIT"}
            </Button>
          </div>
          <Footer />
        </>
      ) : (
        <>
          <HeaderLogo show={false} />
          <h1 className="EndTest__Text">Time is up! Kindly submit the Test.</h1>
          <div className="submitbuttons">
            <Button
              style={{
                backgroundColor: "#3ab54a",
                margin: "30px 10px",
                width: "120px"
              }}
              className="SubmitButton"
              variant="contained"
              onClick={onSubmit}
              disabled={isSubmitting}
            >
              {isSubmitting ? "SUBMITTING..." : "SUBMIT"}
            </Button>
          </div>
          <EndFooter />
        </>
      )}
    </div>
  );
}

export default TestPage;
