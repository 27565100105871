const QuestionList = [
  {
    _id: {
      $oid: "64489bfd1628c94745d27507",
    },
    question:
      "The pressure that a single component in a gaseous mixture would exert if it existed alone in the same volume as the mixture and at the same temperature as the mixture is referred to as.",
    one: "Absolute Pressure",
    two: "Partial Pressure",
    three: "Total pressure of a gas mixture",
    four: "None of the mentioned",
    correct: "two",
    category: "chemistry",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d274fd",
    },
    question: "According to Wien’s  law……",
    one: "λₘT = constant",
    two: "λₘ/T = constant",
    three: "T/λₘ = constant",
    four: "λₘ + T = constant",
    correct: "one",
    category: "physics",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d274ff",
    },
    question:
      "Three resistances each of 1ohm, are joined in parallel. Three such combinations are put in series, then the resultant resistance will be………",
    one: "9 ohm",
    two: "3 ohm",
    three: "1 ohm",
    four: "1/3 ohm",
    correct: "three",
    category: "physics",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d2750e",
    },
    question: "Which of the following is an example of aldehyde group?",
    one: "-CHO",
    two: "-NH₂",
    three: "-OCH₃",
    four: "-NHR",
    correct: "one",
    category: "chemistry",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d274fb",
    },
    question: "|z-1| = 2  represents………………",
    one: "hyperbola",
    two: "parabola",
    three: "ellipse",
    four: "circle",
    correct: "four",
    category: "maths",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d274f5",
    },
    question: "Value of  lim x → 0 [√x tanx / (ex-1)³/²]  is………………. ",
    one: "0",
    two: "2",
    three: "3",
    four: "1",
    correct: "four",
    category: "maths",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d2750d",
    },
    question:
      "What happens to the size of atoms in p-block elements when we move from left to right in the same period",
    one: "Size does not change",
    two: "Size increase then decrease",
    three: "Size increases",
    four: "Size decreases",
    correct: "four",
    category: "chemistry",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d27500",
    },
    question: "One kilowatt hour is equal to…………………",
    one: "10³ joules",
    two: "10⁵ joules",
    three: "36 x 10³ joules",
    four: "36 x 10⁵ joules",
    correct: "four",
    category: "physics",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d27501",
    },
    question: "Lorentz force can be calculated by using the formula…………",
    one: "F = q(2E\u20d7 + v\u20d7.B\u20d7)",
    two: "F = q(E\u20d7 + v\u20d7\u2a2fB\u20d7)",
    three: "F = q(E\u20d7\u2a2fB\u20d7 + v\u20d7)",
    four: "F = q(E\u20d7.B\u20d7 + v\u20d7)",
    correct: "two",
    category: "physics",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d2750f",
    },
    question:
      "Which of the following statements about SN² mechanisms is incorrect?",
    one: "The transition state is stable",
    two: "The complete mechanism takes place in a single step",
    three:
      "The rate of the reaction depends on the concentration of both reactants",
    four: "There is an inversion of configuration",
    correct: "one",
    category: "chemistry",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d274f7",
    },
    question: " Maximum value of sinx + cosx  is……………………",
    one: "2",
    two: "√2",
    three: "1",
    four: "0",
    correct: "two",
    category: "maths",
    image: "0",
    __v: 0,
  },
  {
	"_id": {
	  "$oid": "64489bfd1628c94745d274fc"
	},
	"question": "In the relation y = acos(wt-kx), the dimensional formula for k is .........",
	"one": "M¹L¹T¹",
	"two": "M¹L⁻¹T¹",
	"three": "M⁰L⁻¹T⁰",
	"four": "M⁻¹L⁻¹T¹",
	"correct": "three",
	"category": "physics",
	"image": "0",
	"__v": 0
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d274fa",
    },
    question: "Find perimeter of semicircle with radius 7 cm?",
    one: "44cm",
    two: "21cm",
    three: "36cm",
    four: "14cm",
    correct: "three",
    category: "maths",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d274f3",
    },
    question:
      "If 𝒂⃗ , 𝒃⃗ , 𝒄⃗ are three mutually perpendicular unit vector then value of |𝒂⃗ + 𝒃⃗ + 𝒄⃗ | is ……………",
    one: "3",
    two: "0",
    three: "√3",
    four: "1",
    correct: "three",
    category: "maths",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d27509",
    },
    question:
      "Which of the following does not belong in the category of electrochemical cells?",
    one: "Voltaic cell",
    two: "Photovoltaic cell",
    three: "Electrolytic cell",
    four: "Fuel cell",
    correct: "two",
    category: "chemistry",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d274f8",
    },
    question:
      "Distance between two line  2x+y+2z-8 = 0  and 4x+2y+4z+5 = 0  is……………..  ",
    one: "1.5",
    two: "2.5",
    three: "3.5",
    four: "2",
    correct: "three",
    category: "maths",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d274fe",
    },
    question:
      "Energy stored in a capacitor of capacitance 100µF is 50J. Its potential difference is………….",
    one: "50V",
    two: "500V",
    three: "1000V",
    four: "1500V",
    correct: "three",
    category: "physics",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d27505",
    },
    question:
      "The resistivity of certain metals or alloys drops to zero when they are cooled below a certain temperature, these materials are known as………",
    one: "Conductors",
    two: "Superconductors",
    three: "Semiconductors",
    four: "Insulators",
    correct: "two",
    category: "physics",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d2750b",
    },
    question: "In a reaction, what is the driving force?",
    one: "Energy given",
    two: "Energy released",
    three: "Free energy",
    four: "None of the mentioned",
    correct: "three",
    category: "chemistry",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d27508",
    },
    question: "What makes a solution?",
    one: "Solute and Solvent",
    two: "Solute and Solute",
    three: "Solvent and solvent",
    four: "None of the above",
    correct: "one",
    category: "chemistry",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d274f2",
    },
    question: "If A and B are symmetric matrix, then AB - BA is ……………",
    one: "Symmetric Matrix",
    two: "Skew-Symmetric Matrix",
    three: "Null matrix",
    four: "Identity Matrix",
    correct: "two",
    category: "maths",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d2750c",
    },
    question:
      "What is the energy that is released upon the formation of an ionic compound known as?",
    one: "Ionization energy",
    two: "Lattice Energy",
    three: "Electron gin enthalpy",
    four: "electro positivity",
    correct: "two",
    category: "chemistry",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d274f6",
    },
    question:
      "If A is a square matrix of order 3 such that |A| = 2, then |adjA| is………",
    one: "4",
    two: "8",
    three: "16",
    four: "2",
    correct: "one",
    category: "maths",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d2750a",
    },
    question:
      "In a fuel cell, which of the following can be utilized as a fuel?",
    one: "Nitrogen",
    two: "Argon",
    three: "Hydrogen",
    four: "Helium",
    correct: "three",
    category: "chemistry",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d274f4",
    },
    question:
      "A set X contains n element then how many Reflexive relations on X exists? ",
    one: "2ⁿ⁽ⁿ⁻¹⁾",
    two: "2ⁿ⁽ⁿ⁺¹⁾/²",
    three: "2ⁿ",
    four: "n",
    correct: "one",
    category: "maths",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d27503",
    },
    question: "The momentum of photon of energy hν will be………",
    one: "hv",
    two: "zero",
    three: "hv/c",
    four: "hvc",
    correct: "three",
    category: "physics",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d27502",
    },
    question:
      "The relative permeability is represented by r and susceptibility is denoted by χ for a magnetic substance. Then for a paramagnetic substance……..",
    one: "µᵣ<1, χ<0",
    two: " µᵣ<1, χ>0",
    three: "µᵣ>1, χ<0",
    four: "µᵣ>1, χ>0",
    correct: "four",
    category: "physics",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d274f9",
    },
    question: "Derivative of sin⁻¹x + sec⁻¹(⅟x) is……………",
    one: "0",
    two: "1",
    three: "x",
    four: "2",
    correct: "one",
    category: "maths",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d27504",
    },
    question:
      "To obtain p-type Silicon semiconductor, we need to dope pure Si with....",
    one: "Phosphorous",
    two: "Aluminum",
    three: "Germanium",
    four: "Oxygen",
    correct: "two",
    category: "physics",
    image: "0",
    __v: 0,
  },
  {
    _id: {
      $oid: "64489bfd1628c94745d27506",
    },
    question: "……………….is a crystalline’s basic repetitive structural unit.",
    one: "Monomer",
    two: "Molecule",
    three: "Unit cell",
    four: "Atom",
    correct: "three",
    category: "chemistry",
    image: "0",
    __v: 0,
  },
];

export default QuestionList;
